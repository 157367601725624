<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="pageBack">简历库</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">简历详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              :model="pageInfo"
              ref="ruleForm"
              :rules="rules"
              label-width="150px"
            >
              <el-row>
                <h3 style="padding: 0 0 20px 0;">基本信息</h3>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <el-form-item label="姓名：" prop="userName">
                    <el-input v-model="pageInfo.userName" size="small" placeholder="" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="手机号：" prop="mobile">
                    {{pageInfo.mobile}}
                    &nbsp;
                    <i class="el-icon-view" style="color:#2878FF" v-if="pageInfo.mobile.indexOf('*') > 0" title="查看手机号" @click="viewMobile"></i>
                    <!--                    <el-input v-model="pageInfo.mobile" size="small" placeholder="" disabled></el-input>-->
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <el-form-item label="性别：" prop="sex">
                    <el-select v-model="pageInfo.sex" size="small" placeholder="" disabled>
                      <el-option label="男" value="1"></el-option>
                      <el-option label="女" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="出生日期：" prop="birthday">
                    <el-date-picker
                        v-model="pageInfo.birthday"
                        type="date"
                        size="small"
                        placeholder=""
                        value="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" disabled
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
<!--              </el-row>-->
<!--              <el-row>-->
                <el-col :span="11">
                  <el-form-item label="毕业院校：" prop="graduateInstitutions">
                    <el-input
                        v-model="pageInfo.graduateInstitutions"
                        size="small"
                        placeholder="" disabled
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="参加工作时间：" prop="joinWork">
                    <el-date-picker
                        v-model="pageInfo.joinWork"
                        type="date"
                        size="small"
                        placeholder=""
                        value="yyyy-MM-dd"
                        value-format="yyyy-MM-dd" disabled
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <!-- <el-col :span="11">
                  <el-form-item label="现工作单位：" prop="workUnit">
                    <el-input v-model="pageInfo.workUnit" size="small" placeholder="" disabled></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :span="11">
                  <el-form-item label="最高学历" prop="educationName">
                    <el-input v-model="pageInfo.educationName" size="small" placeholder="" disabled></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="所在城市：" prop="areaId">
                    <el-cascader
                        :options="areaIdData"
                        clearable
                        filterable
                        :props="propsarea"
                        size="small"
                        v-model="pageInfo.areaId" disabled
                    ></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <!-- <el-col :span="11">
                  <el-form-item label="最近职位：" prop="positionName">
                    <el-input v-model="pageInfo.positionName" size="small" placeholder="" @focus="inputpositio" disabled></el-input>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :span="11">
                  <el-form-item label="现居住地：" prop="currentAddress">
                    <el-input v-model="pageInfo.currentAddress" size="small" placeholder="" disabled></el-input>
                  </el-form-item>
                </el-col> -->
<!--              </el-row>-->
<!--              <el-row>-->
                <el-col :span="11">
                  <el-form-item label="求职状态：" prop="jobSeekingState">
                    <el-select
                        clearable
                        size="small"
                        v-model="pageInfo.jobSeekingState"
                        placeholder="" disabled
                    >
                      <el-option
                          v-for="item in jobSeekingStateData"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
<!--              </el-row>-->
<!--              <el-row>-->
                <el-col :span="11">
                  <el-form-item label="人才来源：" prop="talentSource">
                    <el-select
                        clearable
                        size="small"
                        v-model="pageInfo.talentSource"
                        placeholder="" disabled
                    >
                      <el-option
                          v-for="item in talentSourceData"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row
                  v-if="pageInfo.resumeList.length > 0">

                <h3 style="padding: 20px 0;">简历</h3>
              </el-row>
              <el-row
                  v-if="pageInfo.resumeList.length > 0">
                <el-col :span="22">
                  <el-form-item label="">

                    <div
                        style="color: #409eff"
                        v-for="(item, index) in pageInfo.resumeList"
                        :key="index"
                    >
                      <span style="color: #333">{{ item.fileName }}</span>
                      <a
                          @click="lookExl(item)"
                          style="color: #409eff; margin: 0 15px"
                      >预览</a>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>

                <h3 style="padding: 20px 0;">推荐设置</h3>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <el-form-item label="人才评价：">
                    {{ pageInfo.talentLevel?pageInfo.talentLevel+'星':'--' }}
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="推荐系数：">
                    {{ pageInfo.recommendLevel?pageInfo.recommendLevel+'星':'--' }}
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="擅长领域：">
                    {{ pageInfo.skilledField?pageInfo.skilledField:'--' }}
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="兴趣爱好：">
                    {{ pageInfo.hobbies?pageInfo.hobbies:'--' }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="tableData6.length>0">
                
                <h3 style="padding: 20px 0;">职业技能</h3>
              </el-row>
              <el-row v-if="tableData6.length>0">
                <p v-if="tableData6.filter((item)=>item.degreeProficiency == '10').length">精通：<span v-for="item in tableData6" v-if="item.degreeProficiency == '10'" :key="item.skillName">{{item.skillName}};&nbsp;</span></p>
                <p v-if="tableData6.filter((item)=>item.degreeProficiency == '20').length">熟练：<span v-for="item in tableData6" v-if="item.degreeProficiency == '20'" :key="item.skillName">{{item.skillName}};&nbsp;</span></p>
                <p><span v-for="item in tableData6" v-if="item.degreeProficiency != '10'&&item.degreeProficiency != '20'" :key="item.sikllName">{{item.skillName}};&nbsp;</span></p>
              </el-row>
              <el-row v-if="tableData.length>0">

                <h3 style="padding: 20px 0;">工作经历</h3>
              </el-row>
              <el-row v-if="tableData.length>0">
                <el-table
                    ref="table"
                    :data="tableData"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="tableHeader4"
                >
                  <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="100px"
                      :index="indexMethod"
                  />
                  <el-table-column
                      label="公司名称"
                      align="left"
                      prop="companyName"
                      show-overflow-tooltip
                      min-width="160"
                  />
                  <el-table-column
                      label="职位名称"
                      align="left"
                      prop="positionName"
                      show-overflow-tooltip
                      min-width="120"
                  />
                  <el-table-column
                      label="任职时间"
                      align="left"
                      show-overflow-tooltip
                      min-width="160"
                  >
                    <template slot-scope="{ row }">
                      {{row.entryTime}} ~ {{row.resignationTime}}
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="工作描述"
                      align="left"
                      prop="description"
                      show-overflow-tooltip
                      min-width="160"
                  />
                  <el-table-column
                      label="离职原因"
                      align="left"
                      prop="reasons"
                      show-overflow-tooltip
                      min-width="160"
                  />
                </el-table>
              </el-row>
              <el-row v-if="tableData2.length>0">
                
                <h3 style="padding: 20px 0;">项目经历</h3>
              </el-row>
              <el-row v-if="tableData2.length>0">
                <el-table
                    ref="table"
                    :data="tableData2"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="tableHeader4"
                    
                >
                  <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="100px"
                      :index="indexMethod"
                  />
                  <el-table-column
                      label="项目名称"
                      align="left"
                      prop="itemName"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="项目角色"
                      align="left"
                      prop="itemRole"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="项目时间"
                      align="left"
                      prop="mobile"
                      show-overflow-tooltip
                      min-width="160"
                  >
                    <template slot-scope="{ row }">
                      <template v-if="row.beginTime&&row.endTime">
                        {{row.beginTime.substring(0,10)}} ~ {{row.endTime.substring(0,10)}}

                      </template>
                      <template v-else>--</template>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="项目描述"
                      align="left"
                      prop="description"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="项目业绩"
                      align="left"
                      prop="performance"
                      show-overflow-tooltip
                      min-width="100"
                  />
                </el-table>
              </el-row>
              <el-row v-if="tableData3.length>0">

                <h3 style="padding: 20px 0;">教育经历</h3>
              </el-row>
              <el-row v-if="tableData3.length>0">
                <el-table
                    ref="table"
                    :data="tableData3"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="tableHeader4"
                    
                >
                  <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="80px"
                      :index="indexMethod"
                  />
                  <el-table-column
                      label="学校"
                      align="left"
                      prop="schoolName"
                      show-overflow-tooltip
                      min-width="150"
                  />
                  <el-table-column
                      label="学历"
                      align="left"
                      prop="education"
                      show-overflow-tooltip
                      min-width="100"
                  >
                    <template slot-scope="scope">
                      {{ $setDictionary("HR_EDUCATION", scope.row.education) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="专业"
                      align="left"
                      prop="major"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="在校时间"
                      align="left"
                      show-overflow-tooltip
                      min-width="200"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.enrollmentYear | momentDate }} 至
                      {{ scope.row.leaveYear | momentDate }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="是否统招"
                      align="left"
                      prop="manpowerRecruitment"
                      show-overflow-tooltip
                      min-width="100"
                  >
                    <template slot-scope="scope">
                      {{scope.row.manpowerRecruitment? "是":scope.row.manpowerRecruitment!==undefined && scope.row.manpowerRecruitment!==""?"否":""}}
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
              <el-row v-if="tableData4.length>0">

                <h3 style="padding: 20px 0;">求职意向</h3>
              </el-row>
              <el-row v-if="tableData4.length>0">
                <el-table
                    ref="table"
                    :data="tableData4"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="tableHeader4"
                    
                >
                  <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="100px"
                      :index="indexMethod"
                  />
                  <el-table-column
                      label="期望职位"
                      align="left"
                      prop="positionName"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="期望行业"
                      align="left"
                      prop="industryName"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="期望城市"
                      align="left"
                      prop="areaName"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="期望薪资"
                      align="left"
                      show-overflow-tooltip
                      min-width="100"
                  >
                    <template slot-scope="{ row }">
                      <template v-if="row.compensationMin&&row.compensationMax">
                        {{row.compensationMin*1000}} ~ {{row.compensationMax*1000}}
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="工作性质"
                      align="left"
                      prop="jobNature"
                      show-overflow-tooltip
                      min-width="100"
                  >
                    <template slot-scope="{ row }">
                      {{ $setDictionary("HR_JOB_NATURE", row.jobNature) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="是否接受外地务工"
                      align="left"
                      prop="migrantWorkers"
                      show-overflow-tooltip
                      min-width="100"
                  >
                    <template slot-scope="{ row }">
                      {{ row.migrantWorkers == '1'?'是':row.migrantWorkers == '0'?'否':'--' }}
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
              <el-row v-if="tableData5.length>0">

                <h3 style="padding: 20px 0;">备注</h3>
              </el-row>
              <el-row v-if="tableData5.length>0">
                <el-table
                    ref="table"
                    :data="tableData5"
                    size="small"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-cell-style="tableHeader4"
                >
                  <el-table-column
                      label="序号"
                      align="center"
                      type="index"
                      width="100px"
                      :index="indexMethod"
                  />
                  <el-table-column
                      label="操作人"
                      align="left"
                      prop="operator"
                      show-overflow-tooltip
                      min-width="100"
                  />
                  <el-table-column
                      label="操作时间"
                      align="left"
                      prop="createTime"
                      show-overflow-tooltip
                      min-width="100"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.createTime | momentDate }}
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="操作内容"
                      align="left"
                      prop="remark"
                      show-overflow-tooltip
                      min-width="200"
                  />
                </el-table>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览简历 -->
    <el-dialog title="预览简历" :visible.sync="previewLoding" width="80%" top="2%" center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px"></div>
      </div>
    </el-dialog>

    <!-- 最近职位 -->
    <!-- <el-dialog title="最近职位" :visible.sync="previewLoding2" width="40%" top="5%" center>
      <div class="zjzw">
        <div>
          <div
            :class="previewLodingData_1_positionTypeId == item.positionTypeId ? 'ts_1': ''"
            v-for="(item,index) in previewLodingData_1"
            :key="index"
            @click="selectpreview(1,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
            :class="previewLodingData_2_positionTypeId == item.positionTypeId ? 'ts_1': ''"
            v-for="(item,index) in previewLodingData_2"
            :key="index"
            @click="selectpreview(2,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
        <div>
          <div
            :class="previewLodingData_3_positionTypeId == item.positionTypeId ? 'ts_1': ''"
            v-for="(item,index) in previewLodingData_3"
            :key="index"
            @click="selectpreview(3,item,item.positionTypeId)"
          >{{ item.positionTypeName }}</div>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import pdf from "pdfobject";
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import {resetKeepAlive} from "@/utils/common";
export default {
  name: "resumeDetail",
  components: {
    Empty
  },
  mixins: [List],
  data() {
    return {
      // 页面数据
      pageInfo: {
        talentId:"", // 人才信息id
        // photoKey: "", // 头像 - key
        // photoUrl: "", // 头像 - url
        userName: "", // 姓名
        sex: "", // 性别
        mobile: "", // 手机号
        birthday: "", // 出生日期
        graduateInstitutions: "", // 毕业院校
        educationRequirement: "", // 最高学历
        joinWork: "", // 参加工作时间
        // workUnit: "", // 现工作单位
        // positionName: "", // 最近职位 - 页面显示name
        // positionId: "", // 最近职位 - 就是三级类数据id
        talentSource: "", // 人才来源
        // currentAddress: "", // 现居住地
        jobSeekingState: "", // 求职状态
        areaId: "", // 所在城市
        resumeList: [] // 简历
      },
      // 人才来源 - 下拉数据
      talentSourceData: [],
      // 求职状态 - 下拉数据
      jobSeekingStateData: [],
      // 最高学历 - 下拉数据
      educationRequirementList: [],
      // 所在城市 - 下拉数据
      areaIdData: [],
      // 所在城市 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      // 页面数据 - 数据校验
      rules: {
        // userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        // sex: [
        //   {
        //     required: true,
        //     message: "请选择性别",
        //     trigger: "change"
        //   }
        // ],
        // mobile: [
        //   { required: true, validator: this.$validatePhone, trigger: "blur" }
        // ],
        // birthday: [
        //   {
        //     required: true,
        //     message: "请选择时间",
        //     trigger: "change"
        //   }
        // ]
      },
      // 预览简历 - 弹框
      previewLoding: false,
      // 最近职位 - 弹框
    //   previewLoding2: false,
    //   // 最近职位 - 一级类数据 - 也是所有数据
    //   previewLodingData_1: [],
    //   // 最近职位 - 二级类数据
    //   previewLodingData_2: [],
    //   // 最近职位 - 三级类数据
    //   previewLodingData_3: [],
    //   // 最近职位 - 一级类数据 - 当前id
    //   previewLodingData_1_positionTypeId: "",
    //   // 最近职位 - 二级类数据 - 当前id
    //   previewLodingData_2_positionTypeId: "",
    //   // 最近职位 - 三级类数据 - 当前id
    //   previewLodingData_3_positionTypeId: "",



      
      // 工作经历
      tableData: [],
      // 项目经历
      tableData2: [],
      // 教育经历
      tableData3: [],
      // 求职意向
      tableData4: [],
      // 备注
      tableData5: [],
      // 职业技能.
      tableData6: [],
      retrievalData:{},
      currentPage:'',
    };
  },
  mounted() {},
  created() {
    this.pageInfo.talentId = this.$route.query.talentId;
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
    this.getareatree();
    this.getInvoice_type();
    // this.getPreviewLoding2();
    this.getInfo(this.$route.query.talentId);
    this.getWorkExperienceLog(this.$route.query.talentId)
    this.getItemExperienceLog(this.$route.query.talentId)
    this.getEducationExperienceLog(this.$route.query.talentId)
    this.getJobIntentionLog(this.$route.query.talentId)
    this.getRecommendLog(this.$route.query.talentId)
    this.getVocationalSkillLog(this.$route.query.talentId)
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/azhr/humanResources/resumeLibrary/resumeList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 - 工作经历
    getWorkExperienceLog(talentId) {
      this.$post("/hr/talent/info/workExperienceLog", {
        talentId,
      })
          .then((res) => {
            this.tableData = res.data;
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 项目经历
    getItemExperienceLog(talentId) {
      this.$post("/hr/talent/info/itemExperienceLog", {
        talentId,
      })
          .then((res) => {
            this.tableData2 = res.data;
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 教育经历
    getEducationExperienceLog(talentId) {
      this.$post("/hr/talent/info/educationExperienceLog", {
        talentId,
      })
          .then((res) => {
            this.tableData3 = res.data;
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 求职意向
    getJobIntentionLog(talentId) {
      this.$post("/hr/talent/info/jobIntentionLog", {
        talentId,
      })
          .then((res) => {
            this.tableData4 = res.data;
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 备注
    getRecommendLog(talentId) {
      this.$post("/hr/talent/info/recommendLog", {
        talentId,
      })
          .then((res) => {
            this.tableData5 = res.data;
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 职业技能
    getVocationalSkillLog(talentId) {
      this.$post("/hr/talent/info/vocationalSkillLog", {
        talentId,
      })
          .then((res) => {
            this.tableData6 = res.data;
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 详情
    getInfo(talentId) {
      this.$post("hr/talent/info/getById", {
        talentId,
      })
          .then((res) => {
            this.pageInfo = {
              ...res.data,
            };
          })
          .catch((err) => {
            return;
          });
    },
    // 获取 - 城市区划
    getareatree() {
      this.$post("/sys/area/tree")
        .then(res => {
          this.areaIdData = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 人才来源
      let arr1 = this.$setDictionary("HR_TALENT_SOURCE", "list");
      for (const key in arr1) {
        this.talentSourceData.push({
          label: arr1[key],
          value: key
        });
      }
      // 求职状态
      let arr2 = this.$setDictionary("HR_JOB_SEEKING_STATE", "list");
      for (const key in arr2) {
        this.jobSeekingStateData.push({
          label: arr2[key],
          value: key
        });
      }
      // 获取 - 学历字典
      const arr3 = this.$setDictionary("EDUCATION_REQUIREMENT", "list");
      for (const key in arr3) {
        console.log()
        this.educationRequirementList.push({
          value: key,
          label: arr3[key],
        });
      }
    },
    // 预览简历
    lookExl(item) {
      this.previewLoding = true;
      this.$nextTick(() => {
        pdf.embed(item.fileUrl, "#pdf-cert1");
      });
    },
    // 删除简历
    // reomveExlUpload2(index) {
    //   this.pageInfo.resumeList.splice(index, 1);
    //   this.$forceUpdate();
    // },
    // 点击 - 最近职位
    // inputpositio(){
    //   this.previewLoding2 = true;
    // },
    // 获取 - 最近职位：但只取当前第一组数据
    // getPreviewLoding2() {
    //   this.$post("/hr/talent/hr-position-type/queryAll")
    //     .then(res => {
    //       this.previewLodingData_1 = res.data || [];
    //     })
    //     .catch(() => {
    //       return;
    //     });
    // },
    // 点击 - 职业分类
    selectpreview(type,item,positionTypeId){
      if(type == 1) {
        this.previewLodingData_1_positionTypeId = positionTypeId;
        this.previewLodingData_2 = item.children;
        this.previewLodingData_3 = [];
      }
      if(type == 2) {
        this.previewLodingData_2_positionTypeId = positionTypeId;
        this.previewLodingData_3 = item.children;
      }
      if(type == 3) {
        this.previewLodingData_3_positionTypeId = positionTypeId;
        // this.pageInfo.positionId = positionTypeId;
        // this.pageInfo.positionName = item.positionTypeName;
        this.previewLoding2 = false;
      }
    },
    // 查看手机号
    viewMobile(){
      this.$post("/hr/company/resume/viewMobile", {
        talentId: this.pageInfo.talentId
      })
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.pageInfo.mobile = res.data
            }
          })
          .catch((err) => {
            return;
          });
    },

  },

  // beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}
.el-form {
  .el-form-item {
    margin-bottom: 18px;
  }
}
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 33.33%;
    margin-right: 4%;
    > div {
      line-height: 26px;
      padding-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}

//人力资源样式重置
/deep/::-webkit-scrollbar-thumb{
  background-color: #eee;
  border-radius: 0px;
}
/deep/::-webkit-scrollbar-track-piece {
  background: #fff;
  border-radius: 0px;
}
/deep/.el-pager li{
  background-color: #fff;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .el-pager li{
  background-color: #fff;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #2878FF;
  border: 1px solid #2878FF;
}
/deep/.el-pagination.is-background .btn-next, /deep/.el-pagination.is-background .btn-prev{
  border: 1px solid #d9d9d9;
  background-color: #fff;
}
/deep/.el-pager li.active+li{
  border-left: 1px solid #d9d9d9;
}
/deep/.el-pagination.is-background .btn-next.disabled, /deep/.el-pagination.is-background .btn-next:disabled, /deep/.el-pagination.is-background /deep/.btn-prev.disabled, /deep/.el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background-color: #f4f4f5;
  border: 1px solid #d9d9d9;
}
/deep/.el-pagination button{
  border: 1px solid #d9d9d9;
}
/deep/.el-button--text{
  //color: #2878FF;
  font-size: 14px!important;
}
/deep/.bgc-bv{
  background-color: #2878FF !important;
  color: #fff !important;
  border-color: #2878FF !important;
  border-radius: 0;
}
/deep/.el-table th.el-table__cell>.cell{
  font-size: 14px!important;
  font-weight: 400!important;
}
/deep/.el-dialog__header{
  background-color: #fff;
}
/deep/.el-dialog__header .el-dialog__title{
  color: #333;
}
/deep/.el-dialog__header .el-icon-close:before{
  color: #333;
}
/deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
  border-color: #2878FF !important;
}
</style>